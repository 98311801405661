const Navbar = ({ logout, title, user }) => {

    //Style Guide: https://purolatormarketing.com/styleguide/
    return (
        <div className="flex justify-between py-5 bg-white w-100 px-10 shadow-md z-10">
         <div className="flex gap-2 items-center">
            <img className="h-6 mr-3" src={require("../assets/Puro_Logo.png")} alt="PurolatorLogo"/>
            <p className="text-2xl italic font-bold tracking-tighter">{title}</p>
         </div>
         <div className="flex gap-2">
            <p className="my-auto">{user}</p>
            <button className="text-sm border bg-[#F00000] hover:bg-[#F06464] rounded text-white px-2" onClick={logout}>Logout</button>
         </div>
      </div>
    );
};

export default Navbar;
