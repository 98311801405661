import './App.css';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';

//Okta Ideneeity Provider
const oktaAuth = new OktaAuth({
  issuer: 'https://purolator.okta.com/oauth2/default',
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  redirectUri: process.env.REACT_APP_WEBURL,
  postLogoutRedirectUri: 'https://purolator.okta.com/app/UserHome'
});



const App = () => {
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={process.env.REACT_APP_WEBURL}>
      <Routes>
        <Route path='/index.html' exact={true} element={<Home/>} />
      </Routes>
    </Security>
  );
};

const AppWithRouterAccess = () => (
  //Routes needed for Security tag for Okta sdk
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <App />
  </BrowserRouter>
);

export default AppWithRouterAccess;
