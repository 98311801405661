//AWS SDK
import { CognitoIdentityClient, GetIdCommand, GetCredentialsForIdentityCommand } from "@aws-sdk/client-cognito-identity";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";

const getTemporaryCredentials = async (token, poolID) => {

    const cognitoClient = new CognitoIdentityClient({ region: "us-east-1" });

    const getId = new GetIdCommand({
        IdentityPoolId: poolID,
        //Logins: OKTA OIDC Idenetity provider
        Logins: {
            'purolator.okta.com/oauth2/default': token
        },
    });
    const getIdResponse = await cognitoClient.send(getId)

    const getCredentails = new GetCredentialsForIdentityCommand({
        IdentityId: getIdResponse.IdentityId,
        Logins: {
            'purolator.okta.com/oauth2/default': token
        },
    });
    const getCredentailsResponse = await cognitoClient.send(getCredentails);

    return {
        accessKeyId: getCredentailsResponse.Credentials.AccessKeyId,
        secretAccessKey: getCredentailsResponse.Credentials.SecretKey,
        sessionToken: getCredentailsResponse.Credentials.SessionToken
    }
}



const getQuicksightUrl = async (token) => {
    const credentials = await getTemporaryCredentials(token, process.env.REACT_APP_IDPOOL)

    const client = new LambdaClient({
        region: 'us-east-1',
        credentials,

    });

    const command = new InvokeCommand({
        FunctionName: process.env.REACT_APP_LAMBDA_FUNCTION,
        Qualifier: process.env.REACT_APP_LAMBDA_ALIAS,
    });
    const response = await client.send(command);

    //decode payload
    const payload = JSON.parse(new TextDecoder().decode(response.Payload));

    //handle error
    if (payload.Status === 200) {
        return payload.EmbedUrl
    }
    else {
        console.error('Lambda invocation failed with status code:', payload.Status);
        throw new Error('Internal server error with status code: ' + payload.Status)
    }

}


export {
    getQuicksightUrl,
};
