import { useState, useEffect } from 'react';
import { getQuicksightUrl } from '../functions/awsFunctions'
import Navbar from './Navbar'
import { useOktaAuth, LoginCallback } from '@okta/okta-react';


const Home = () => {

  const { oktaAuth, authState } = useOktaAuth();
  const [url, setUrl] = useState("")
  const [user, setUser] = useState("")
  const [error, setError] = useState(null)

  const logout = async () => oktaAuth.signOut()

  useEffect(() => {
    const initializeState = async () => {

      if (authState && !authState.isAuthenticated) {
        await oktaAuth.signInWithRedirect()
      }

      oktaAuth.token.getUserInfo()
        .then((user) => setUser(user.email))

      setQuciksightUrl()
    }

    const setQuciksightUrl = () => {
      getQuicksightUrl(authState.accessToken.accessToken)
        .then((url) => {
          setUrl(url)
          setError(null)
        })
        .catch(err => setError(err.message))
    }

    initializeState()

    console.log(process.env.REACT_APP_OKTA_CLIENTID)
    console.log(process.env.REACT_APP_IDPOOL)
    console.log(process.env.REACT_APP_LAMBDA_FUNCTION)
    console.log(process.env.REACT_APP_LAMBDA_ALIAS)
    console.log(process.env.REACT_APP_WEBURL)
    console.log(process.env.PUBLIC_URL)


    //Interval 15 minute refresh
    /*
    const delay = 1 * 60 * 1000

    const intervalId = setInterval(setQuciksightUrl, delay);

    return () => clearInterval(intervalId);
*/
  }, [authState, oktaAuth]);

  if (!authState) {
    return <LoginCallback/>;
  }


  //Style Guide: https://purolatormarketing.com/styleguide/
  return (
    <div className="h-screen bg-gray-200 font-sans flex flex-col" >
      <Navbar title={"App Performance"} logout={logout} user={user}/>
        <div className="flex-1">
          {!error && <iframe className="w-full h-full" src={url} title="App Performance"></iframe>}
          {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default Home;
